import React from "react"



const SSS = () => (


    <div className="hiring-tabs">
        <input type="checkbox" name="dropdown" id="tab1" />
        <input type="checkbox" name="dropdown" id="tab2" />
        <input type="checkbox" name="dropdown" id="tab3" />
        <input type="checkbox" name="dropdown" id="tab4" />
        <input type="checkbox" name="dropdown" id="tab5" />

        <div className="tab" id="tab1sec">
            <label htmlFor="tab1"> </label>
            <h3>What is the goal of content marketing?</h3>
            <p>In the most basic way, we can say that its main goal is to influence the target audience through content and to establish a sustainable communication bond. This will increase the </p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab2sec">
            <label htmlFor="tab2"> </label>
            <h3>Is getting a one-time content marketing service sufficient?</h3>
            <p>Content marketing helps to achieve goals when it is maintained on a regular basis. Therefore, doing content marketing work consistently will always be more beneficial than getting a one-time service.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab3sec">
            <label htmlFor="tab3"> </label>
            <h3>Which sectors can benefit from content marketing?</h3>
            <p>Regardless of the sector, any brand that wants to reach its target audience and offer them value can benefit from content marketing.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab4sec">
            <label htmlFor="tab4"> </label>
            <h3>Is content marketing different from SEO?</h3>
            <p>The answer is yes. SEO (Search Engine Optimization) represents all the improvement efforts made to rank websites higher in search engine results. Content marketing, on the other hand, is one of the methods used to reach the target audience.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
    
    </div>
)

export default SSS
