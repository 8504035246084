import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Company from "../components/content-company"
import rightArrow from "../assets/img/right-arrow.png";

import icerik from "../assets/img/icerik-pazarlama.png"
import blog from "../assets/img/pazarlama-icerikleri.png"
import pazarlama from "../assets/img/blog-icerik.png"
import yapisal from "../assets/img/yapisal-icerikler.png"
import work1 from "../assets/img/work-preformance.png"
import Teams from "../components/teams/seo-team"
import SSS from "../components/sss/content-sss"
import Services from "../assets/img/services-head.png"

import ikon1 from "../assets/img/ip-ikon1.png"
import ikon2 from "../assets/img/ip-ikon2.png"
import ikon3 from "../assets/img/ip-ikon3.png"
import ikon4 from "../assets/img/ip-ikon4.png"
import ikon5 from "../assets/img/ip-ikon5.png"
import ikon6 from "../assets/img/ip-ikon6.png"
import ikon7 from "../assets/img/ip-ikon7.png"
import ikon8 from "../assets/img/ip-ikon8.png"
import ikon9 from "../assets/img/ip-ikon9.png"
import ikon10 from "../assets/img/ip-ikon10.png"



import checkModal from "../assets/img/check-modal.png"

class SocialMedia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');

        document.querySelector('#site').innerHTML = this.state.value;
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Success!";
            }
            else {
                document.querySelector('.status').innerHTML = "Error";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="Content Marketing" description="We produce content in various channels from blog posts to social media posts and guide your target audience effectively." />
                <div className="seo-service container">
                    <div className="seo-service__head">
                        <div className="col-md-6 noPadding">
                            <h1>Content <br /> Marketing</h1>
                            <span className="red-head">SERVICES</span>
                            <p>While attracting the attention of the target audiences of the brands we serve, we produce original content that will overlap with the digital marketing strategy. </p>
                            <input type="text" className="pink-input" id="email-marketing-input" placeholder="Enter your e-mail address" value={this.state.value} onChange={this.handleChange} />
                                    <button className="red-button" id="email-marketing-button" onClick={(e) => this.showModal()}>Get your special offer</button>
                        </div>
                        <div className="col-md-6" style={{ marginTop: -18.5, paddingLeft: 30 }}><img alt="alt text" src={Services}  /> </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Fill the form</h2>                               <p className="form-desc">Enter your information in the fields to get information, and we will get back to you as soon as possible.</p>
                            <div className="check-modal_container">
                            <form onSubmit={this.handleSubmit} >
                                    <input type="text" name="text_204202" id="site" required  placeholder="Enter your website." />
                                    <input type="text" id="name" required name="text_775908" placeholder="Enter your name" />
                                    <input type="email" id="email" required  name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="Enter yor email" />
                                    <button  >SEND</button>
                                    <div className="status"></div>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                      
                    </div>
                </div>

                <div className="container brands_content py60">
                    
                    <div className="studies">
                        <h2 style={{ marginBottom: 50, marginTop: -50 }}>We help our brands communicate with their existing and potential customers by producing engaging, original content that is in line with changing trends.</h2>
                    </div>
                    <h2>Our References</h2>
                     <p>Some of our featured reference brands </p>
                    <div className="companys__social">
                        <Company />
                    </div>
                </div>

                <div className="container brands_content py60" style={{ marginTop: 20 }}>
                    <h2>Content Marketing Services</h2>
                </div>

                <div className="acquire-bottom social-media-contents container">
                    <div className="acquire-bottom__left new">
                        <div className="acquire-bottom__left-img"><img alt="alt text" src={pazarlama} style={{ width: '100%' }} /></div>
                        <div className="acquire-bottom__left-content">
                        <h2>Marketing <br /> Content</h2>
                            <p>
                            Position your brand in the minds of your target audience with the unique and value-creating website content we will produce.</p>
                        </div>
                    </div>

                    <div className="acquire-bottom__right new" >
                        <div className="acquire-bottom__left-content" id="icerik-pazarlama">
                        <h2>Structured Content</h2>
                        <p>Reach your existing and potential customers with relevant content we will produce for your product/service and category pages.</p>
                        </div>
                        <div className="acquire-bottom__right-img"><img alt="alt text" src={yapisal} style={{ width: '100%' }} /></div>
                    </div>
                    <div className="acquire-bottom__left new">
                        <div className="acquire-bottom__left-img"><img alt="alt text" src={blog} style={{ width: '100%' }}/></div>
                        <div className="acquire-bottom__left-content">
                            <h2>SEO-Friendly <br />Blog Content</h2>
                            <p>Increase your organic traffic with blog content that we will prepare in accordance with the web trends of your target audience.</p>

                        </div>
                    </div>
                </div>

                <div className="onboarding container py60" >
                    <div className="col-md-4">
                        <img alt="alt text" src={work1} />
                        <h2>Our Content <br />Marketing process.</h2>
                        <p>Check out how we handle content marketing for our brands.</p>
                    </div>
                    <div className="col-md-8">
                        <div className="hiring-tabs">
                            <input type="checkbox" name="onboarding" id="tab6" />
                            <input type="checkbox" name="onboarding" id="tab7" />
                            <input type="checkbox" name="onboarding" id="tab8" />
                            <input type="checkbox" name="onboarding" id="tab9" />
                            <input type="checkbox" name="onboarding" id="tab10" />
                            <input type="checkbox" name="onboarding" id="tab11" />

                            <div className="tab" id="tab6sec">
                                <label htmlFor="tab6"> </label>
                                <h3> <span>1</span> Defining the Goals </h3>
                                <p>- We identify in which area there is a need for content marketing and which marketing channels we aim to contribute to with this work.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab7sec">
                                <label htmlFor="tab7"> </label>
                                <h3> <span>2</span> Research and Analysis</h3>
                                <p>- The audience to be reached is profiled, user trends are analyzed, and competitors' activities are monitored.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab8sec">
                                <label htmlFor="tab8"> </label>
                                <h3> <span>3</span> Creating Content Plan</h3>
                                <p>- Formats suitable for the platform where the content will be placed are determined and content creation begins.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab9sec">
                                <label htmlFor="tab9"> </label>
                                <h3> <span>4</span> Making Content Available to Users</h3>
                                <p>- After publishing the content, on-page and off-page activities are carried out to ensure that the content meets the right audience.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab10sec">
                                <label htmlFor="tab10"> </label>
                                <h3> <span>5</span>Performance Measurement and Evaluation</h3>
                                <p>- User interaction and the impact of content on KPIs are regularly measured and reported.</p>
                                <div className="plus"></div>
                            </div>
                            
                        </div>
                    </div>
                </div>

             
 
                <div className="container brands_content py60" style={{marginBottom:20,  marginTop: 10}}>
                    <h2 style={{marginBottom:50}}>Top 10 Benefits of <br />Content Marketing for Brands</h2>
                         <div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon1} />
                                <div>
                                <p style={{fontSize:18}}> 1. Ensuring Brand Awareness</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon2} />
                                <div>
                                <p style={{fontSize:18}}> 2.  Increasing Reputation</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon3} />
                                <div>
                                <p style={{fontSize:18}}> 3. Creating a Bond of Trust with the Target Audience</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon4} />
                                <div>
                                <p style={{fontSize:18}}> 4. Opportunity to Reach Wider Audiences</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon5} />
                                <div>
                                <p style={{fontSize:18}}> 5. Acquiring Quality Traffic</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon6} />
                                <div>
                                <p style={{fontSize:18}}> 6. Increasing Conversion Potential</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon7} />
                                <div>
                                <p style={{fontSize:18}}> 7. Increasing Search Engine Visibility</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon8} />
                                <div>
                                <p style={{fontSize:18}}> 8. Increasing Social Media Interaction</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img"alt="alt text" src={ikon9} />
                                <div>
                                    <p style={{fontSize:18}}> 9. Reducing Marketing Costs</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon10} />
                                <div>
                                <p style={{fontSize:18}}> 10. Improving Customer Experience</p>
                                </div>
                            </div>
                        </div>
                </div>
              
                {/*<div className="team">
                    <h2 style={{ textAlign: 'center', marginBottom: 120 }}> Size yardımcı olabilecek <br />Google Ads uzmanlarımız. </h2>
                    <Teams />
                </div>*/}
                <div className="hiring" style={{ marginTop: -20 }}>
                    <h2>Frequently Asked Questions</h2>
                    <SSS />
                </div>
            </Layout>
        )
    }
}
export default SocialMedia
