import React from "react"
import bengu from "../../assets/img/teams/17.jpg"
import aysima from "../../assets/img/teams/35.jpg"




const Teams = () => (

    <div className="team-list py60">
{/*         <div className="team-list__by">
            <div className="image"><img alt="alt text" src={bengu}></img></div>
            <p className="name">Bengü Dinçer</p>
            <p className="job">SEO Team Leader</p>
            <div className="social">
                <div className="linkedin">
                    <a target="_blank" href="https://www.linkedin.com/in/beng%C3%BC-sar%C4%B1ca-din%C3%A7er-03a97645/">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.61" height="20.61" viewBox="0 0 27.61 27.61">
                            <g id="linkedin" transform="translate(0 0.001)">
                                <circle id="Ellipse_54" data-name="Ellipse 54" cx="13.805" cy="13.805" r="13.805" transform="translate(0 -0.001)" fill="#111" />
                                <g id="Group_744" data-name="Group 744" transform="translate(6.661 5.898)">
                                    <path id="Path_894" data-name="Path 894" d="M42.46,32.986v5.691h-3.3v-5.31c0-1.333-.476-2.244-1.671-2.244A1.8,1.8,0,0,0,35.8,32.33a2.259,2.259,0,0,0-.109.8v5.543h-3.3s.044-8.993,0-9.924h3.3v1.406c-.007.011-.016.022-.022.032h.022v-.032a3.276,3.276,0,0,1,2.974-1.639c2.171,0,3.8,1.418,3.8,4.466ZM28.935,23.969a1.72,1.72,0,1,0-.043,3.43h.021a1.72,1.72,0,1,0,.022-3.43ZM27.264,38.678h3.3V28.753h-3.3Z" transform="translate(-27.068 -23.969)" fill="#f1f2f2" />
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>
            </div>
            {/* <p className="description">“ Aaa ne yiyorsunuz bakim” Valla bi’ şey yemiyoruz Derya <span role="img" aria-label>🤭</span>Hepimiz diyetimize dikkat ediyoruz. </p> */}
        {/* </div> */}
         
        {/* <div className="team-list__by"> 
            <div className="image"><img alt="Hatice Özşahan" src={aysima}></img></div>
            <p className="name">Aysima Bakırcılar</p>
            <p className="job">Content Writer</p>
            <div className="social">
                <div className="linkedin">
                    <a target="_blank" href="https://tr.linkedin.com/in/aysimabakircilar">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.61" height="20.61" viewBox="0 0 27.61 27.61">
                            <g id="linkedin" transform="translate(0 0.001)">
                                <circle id="Ellipse_54" data-name="Ellipse 54" cx="13.805" cy="13.805" r="13.805" transform="translate(0 -0.001)" fill="#111" />
                                <g id="Group_744" data-name="Group 744" transform="translate(6.661 5.898)">
                                    <path id="Path_894" data-name="Path 894" d="M42.46,32.986v5.691h-3.3v-5.31c0-1.333-.476-2.244-1.671-2.244A1.8,1.8,0,0,0,35.8,32.33a2.259,2.259,0,0,0-.109.8v5.543h-3.3s.044-8.993,0-9.924h3.3v1.406c-.007.011-.016.022-.022.032h.022v-.032a3.276,3.276,0,0,1,2.974-1.639c2.171,0,3.8,1.418,3.8,4.466ZM28.935,23.969a1.72,1.72,0,1,0-.043,3.43h.021a1.72,1.72,0,1,0,.022-3.43ZM27.264,38.678h3.3V28.753h-3.3Z" transform="translate(-27.068 -23.969)" fill="#f1f2f2" />
                                </g>
                            </g>
                        </svg>
                    </a>

                </div>
            </div>
            {/* <p className="description">“ Aaa ne yiyorsunuz bakim” Valla bi’ şey yemiyoruz Derya <span role="img" aria-label>🤭</span>Hepimiz diyetimize dikkat ediyoruz. </p> */}
        {/* </div> */}
        
    </div>
)

export default Teams
