import { Link } from "gatsby"
import React from "react"

import dogtas from "../assets/img/companys/44.png"
import tanoto from "../assets/img/companys/26.png"
import liva from "../assets/img/companys/17.png"
import vetrium from "../assets/img/companys/45.png"
import pakmaya from "../assets/img/companys/09.png"
import camhotel from "../assets/img/companys/46.png"
import nills from "../assets/img/companys/35.png"
import kentpark from "../assets/img/companys/36.png"





const CompanysSEO = () => (
    <div className="company-component">
        <div className="company-box">
            <img src={dogtas} />
        </div>
        <div className="company-box">
            <img src={tanoto} />
        </div>
        <div className="company-box">
            <img src={liva} />
        </div>
        <div className="company-box">
            <img src={vetrium} />
        </div>
        <div className="company-box">
            <img src={camhotel} />
        </div>
        <div className="company-box">
            <img src={nills} />
        </div>
        <div className="company-box">
            <img src={pakmaya} />
        </div>
        
        
    </div>
)

export default CompanysSEO
